import WebFont, { Config } from 'webfontloader'

export default class WebfontLoader {
  options: Config

  constructor() {
    this.options = {
      custom: {
        urls: [
          // Noto Sans JP - Regular, Bold
          'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap',
          // // Zen Kaku Gothic New - Regular:400, Medium:500, Bold:700
          // 'https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@400;700&display=swap',
          // IBM Plex Sans
          'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&display=swap',
          // Yaku Han JP - Noto base
          'https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp-noto.min.css',
        ],
      },
      // loading: function() {},
      // active: function() {},
      // inactive: function() {},
      // fontloading: function(familyName, fvd) {},
      // fontactive: function(familyName, fvd) {},
      // fontinactive: function(familyName, fvd) {}
    }

    this.setupWebFont()
  }

  setupWebFont() {
    WebFont.load(this.options)
  }
}
