import PubSub from 'vanilla-pubsub'
import { isMobile, getBrowser } from './utils'
import { throttle, debounce } from 'throttle-debounce'

// Types
import { Modules } from '@/assets/js/types/app'

export type WindowState = {
  isMobile: boolean
  browser: string
  width: number
  height: number
  scrollX: number
  scrollY: number
}

class Project {
  $modules: Modules
  $window: WindowState
  scrollBarWidth: number

  constructor() {
    this.$modules = {}
    this.$window = {
      isMobile: isMobile(),
      browser: getBrowser(),
      width: window.innerWidth,
      height: window.innerHeight,
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
    }
    this.scrollBarWidth = window.innerWidth - document.body.clientWidth
    this._bind()
  }

  get state() {
    return this.$window
  }

  get modules() {
    return this.$modules
  }

  _bind() {
    this._onScroll = this._onScroll.bind(this)
    this._onResize = this._onResize.bind(this)
    this._scrollDisable = this._scrollDisable.bind(this)
    this._scrollRelease = this._scrollRelease.bind(this)

    window.addEventListener('scroll', this._onScroll, { passive: true })
    window.addEventListener('resize', this._onResize, { passive: true })
    window.addEventListener('load', this._onLoad)

    PubSub.subscribe('App.methods.scroll.disable', this._scrollDisable)
    PubSub.subscribe('App.methods.scroll.release', this._scrollRelease)
  }

  _onScroll() {
    this.$window.scrollX = window.pageXOffset
    this.$window.scrollY = window.pageYOffset
    PubSub.publish('App.scroll.sensitive', this.state)

    throttle(200, () => {
      PubSub.publish('App.scroll', this.state)
    })()
  }

  _onResize() {
    const oldState = this.$window.isMobile
    this.$window.width = window.innerWidth
    this.$window.height = window.innerHeight
    this.$window.isMobile = isMobile()
    PubSub.publish('App.resize', this.state)

    if (oldState !== this.$window.isMobile) {
      PubSub.publish('App.changeViewport', this.state)
    }

    const debounceFunc = debounce(500, () => {
      PubSub.publish('App.resizeDebounced', this.state)
    })
    debounceFunc()
  }

  _scrollDisable() {
    document.body.style.paddingRight = `${this.scrollBarWidth}px`
    document.documentElement.classList.add('is-disable-scroll')
  }

  _scrollRelease() {
    document.documentElement.classList.remove('is-disable-scroll')
    document.body.style.paddingRight = ''
  }

  _onLoad() {
    PubSub.publish('App.load', this.state)
  }

  // インスタンス化済のモジュールを追加
  addModules(modules: Modules = {}) {
    if (!(modules instanceof Object)) {
      console.error("Error: 'modules' must be Object")
      return
    }

    ;(Object.keys(modules) as string[]).forEach((name) => {
      this.addModule(name, (modules as Modules)[name])
    })
  }

  addModule(name: string, instance: any) {
    this.$modules[name] = instance
  }
}

export default new Project()
